import SmoothScroll from 'smooth-scroll';

import { hamburger } from './modules/hamburger';
import { mega } from './modules/mega';
import { pageTop } from './modules/pageTop';
import { scrollmagic } from './modules/scrollmagic';
hamburger();
mega();
pageTop();
scrollmagic();

// anchor smooth
const scroll = new SmoothScroll('a[href*="#"]', {
  header: '#header',
  speed: 400,
});
