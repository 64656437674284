import { throttle } from 'lodash';

const megaTrigger = document.querySelectorAll('.js-megaTrigger');
const megaTriggerNode = Array.prototype.slice.call(megaTrigger, 0);

export function mega(width) {
  window.addEventListener(
    'resize',
    _.throttle(
      e => {
        megaOver();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );

  window.addEventListener(
    'load',
    _.throttle(
      e => {
        megaOver();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );
}

function megaOver() {
  const width = window.innerWidth;

  if (width > 767) {
    megaTriggerNode.forEach(targetBox => {
      targetBox.addEventListener('mouseenter', () => {
        const childBox = targetBox.lastElementChild;
        childBox.classList.add('is-view');
        targetBox.classList.add('is-active');
      });
    });

    megaTriggerNode.forEach(targetBox => {
      targetBox.addEventListener('mouseleave', () => {
        const childBox = targetBox.lastElementChild;
        childBox.classList.remove('is-view');
        targetBox.classList.remove('is-active');
      });
    });
  } else {
    // sp時はアコーディオン
  const headerSp = document.querySelectorAll('.js-headerSp');
  const headerSpNode = Array.prototype.slice.call(headerSp, 0);

  headerSpNode.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      if (targetBox.classList.contains('is-active')) {
        targetBox.classList.remove('is-active');
        targetBox.nextElementSibling.classList.remove('is-view');
      } else {
        targetBox.classList.add('is-active');
        targetBox.nextElementSibling.classList.add('is-view');
      }
    });
  });
  }
}
