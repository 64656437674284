const hamburgerTrigger = document.querySelector('.js-hamburgerTrigger');
const headerNav = document.querySelector('.js-headerNav');

export function hamburger() {
  // ハンバーガー表示
  hamburgerToggle();
}

function hamburgerToggle() {
  hamburgerTrigger.addEventListener('click', () => {
    if (hamburgerTrigger.classList.contains('is-active')) {
      hamburgerTrigger.classList.remove('is-active');
      headerNav.classList.remove('is-view');
      document.querySelector('body').classList.remove('is-fixed');
    } else {
      hamburgerTrigger.classList.add('is-active');
      headerNav.classList.add('is-view');
      document.querySelector('body').classList.add('is-fixed');
    }
  });
}
